import React, { Component } from "react";

export default class footer extends Component {
  render() {
    return (
      <div className="site-footer" id="footer">
        <div className="container">
          <span>{this.props.siteName}<br/><br/>	&#11049;	&#11049;	&#11049; <br/> <div className="footerline2">Design by <a href={this.props.siteUrl}>{this.props.siteUrlLinktext}</a>. Implemented by <a href={this.props.siteUrl2}>{this.props.siteUrl2LinkText}</a>.</div></span>
        </div>
      </div>
    );
  }
}
